.c-home {
  overflow: hidden;

  &-about {
    margin-top: 140px;
    margin-bottom: 300px;

    @include media-breakpoint-down(md) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 70px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    &__wrap {
      display: flex;
      margin: 0 -40px;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        margin: 0 -20px;
      }

      .c-polygons-item {

        &:nth-child(1) {
          width: 2.56578%;
          left: 57.23684%;
          top: 50%;

          @include media-breakpoint-down(sm) {
            top: 30%;
          }
        }

        &:nth-child(2) {
          width: 7.03947368%;
          right: -40px;
          top: 13px;

          @include media-breakpoint-down(xl) {
            right: 100px;
          }
        }

        &:nth-child(3) {
          width: 11.710526%;
          right: 0;
          bottom: -60px;

          @include media-breakpoint-down(sm) {
            top: 30%;
          }
        }

        &:nth-child(4) {
          width: 39.47368%;
          left: calc(50% - 39.47368%/2);
          bottom: -145px;
          z-index: 0;

          @include media-breakpoint-down(lg) {
            bottom: -70px;
          }
        }
      }
    }

    &__media,
    &__info {
      width: 50%;
      padding: 0 40px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0 20px;
      }
    }

    &__media {

      @include media-breakpoint-down(sm) {
        order: 1;
      }

      &-wrap {
        margin-right: -40px;
        margin-left: -40px;

        @media (min-width: 1520px) {
          margin-left: calc(((100vw - 1530px) / 2 + 40px + 40px) * -1);
        }

        @media (min-width: 1920px) {
          margin-left: -275px;
        }

        @include media-breakpoint-down(sm) {
          margin-left: -40px;
          margin-right: -40px;
        }

        @include media-breakpoint-down(xs) {
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }

    &__video {
      background-position: center;
      background-size: cover;

      &:before {
        padding-top: 60%;
      }

      &-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        background-color: rgba($secondary, .5);
        padding: 0;
        cursor: pointer;

        &:hover {
          background-color: rgba($primary, .5);
        }
      }

      &-play {
        width: 100px;
        height: 100px;
        box-shadow: 10px 10px 20px 0 rgba(#656464, .4);
        border-radius: 50%;

        @include media-breakpoint-down(lg) {
          width: 70px;
          height: 70px;
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }

      iframe {
        z-index: 1;

        @media (min-width: 1520px) and (max-width: 1930px) {
          left: 40px;
          right: 0;
          width: calc(100% - 40px);
        }
      }
    }

    &__info {
      padding-top: 20px;
      position: relative;
      z-index: 1;

      @include media-breakpoint-down(md) {
        padding-top: 0;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }

      &-wrap {
        margin-left: auto;
        width: 100%;
        max-width: 480px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }
      }
    }

    &__logo {
      width: 116px;
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        display: none;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__text {
      font-size: 18px;

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-goal {
    margin-bottom: 300px;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 100px;
    }

    &__wrap {
      display: flex;
      margin: 0 -40px;
      align-items: center;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        margin: 0 -20px;
      }

      .c-polygons-item {

        &:nth-child(1) {
          width: 4.8684%;
          left: 13.15789%;
          top: 70px;

          @include media-breakpoint-down(sm) {
            left: 60%;
          }
        }

        &:nth-child(2) {
          width: 7.960526%;
          left: 29.60526%;
          top: -60px;

          @include media-breakpoint-down(sm) {
            left: 80%;
          }
        }

        &:nth-child(3) {
          width: 15.06578%;
          left: 40.4605%;
          bottom: -100px;
          z-index: 0;

          @include media-breakpoint-down(md) {
            bottom: -50px;
          }

          @include media-breakpoint-down(sm) {
            bottom: 55%;
          }

          @include media-breakpoint-down(xs) {
            bottom: 50%;
            z-index: -1;
          }
        }

        &:nth-child(4) {
          width: 18.55263%;
          left: -5.263157%;
          top: 145px;

          @include media-breakpoint-down(md) {
            top: 70px;
          }
        }
      }
    }

    &__media,
    &__info {
      width: 50%;
      padding: 0 40px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0 20px;
      }
    }

    &__info {
      position: relative;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }

      &-wrap {
        width: 100%;
        max-width: 480px;
      }
    }

    &__text {
      font-size: 18px;
      margin-bottom: 60px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__media {

      &-wrap {
        margin-right: -40px;
        margin-left: -40px;

        @media (min-width: 1520px) {
          margin-right: calc(((100vw - 1530px) / 2 + 40px + 40px) * -1);
        }

        @media (min-width: 1920px) {
          margin-right: -275px;
        }

        @include media-breakpoint-down(sm) {
          margin-left: -40px;
          margin-right: -40px;
        }

        @include media-breakpoint-down(xs) {
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }

    &__img {

      &:before {
        padding-top: 66.7%;
      }
    }
  }

  &-education {
    text-align: center;
    margin-bottom: 300px;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 100px;
    }

    &__wrap {
      position: relative;

      .c-polygons-item {

        &:nth-child(1) {
          width: 5.5555555%;
          right: 6.25%;
          top: 30px;

          @include media-breakpoint-down(lg) {
            top: -40px;
          }
        }

        &:nth-child(2) {
          width: 5.9027777%;
          left: 34.027777%;
          bottom: -200px;

          @include media-breakpoint-down(md) {
            bottom: -100px;
          }

          @include media-breakpoint-down(xs) {
            left: 20%;
          }
        }

        &:nth-child(3) {
          width: 9.58333%;
          left: 5.9027777%;
          top: 18px;

          @include media-breakpoint-down(lg) {
            left: 15px;
            top: -30px;
          }
        }

        &:nth-child(4) {
          width: 18.541666%;
          right: 5.20833333%;
          bottom: -200px;
          z-index: 1;

          @include media-breakpoint-down(xs) {
            bottom: -100px;
          }
        }
      }
    }

    &__title {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    &__text {
      max-width: 800px;
      margin: 0 auto;
      display: block;
      font-size: 24px;
      line-height: 32px;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 26px;
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-type {
    padding: 120px 0;
    margin-bottom: 200px;
    text-align: center;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
      padding: 70px 0;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 100px;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: .2;

      @extend .u-primary-gradient;
    }

    &__wrap {
      max-width: 1100px;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 80px;

      @include media-breakpoint-down(md) {
        margin-bottom: 60px;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(md) {
        margin-bottom: 60px;
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }

    &__item {
      width: 33.333%;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:not(:last-child) {

        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }

      &-title {
        display: block;
        margin-bottom: 20px;
        font-size: 24px;

        @include media-breakpoint-down(md) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      &-text {
        display: block;
        font-size: 14px;
      }
    }
  }
}
