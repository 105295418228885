//# Buttons


.btn {
  font-weight: 700;

  &-primary {
    color: $white;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 0;
      box-shadow: 0 5px 20px 0 rgba($primary, .7);
      z-index: -1;
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $white;
    }
  }

  &-success {
    color: $white;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
        bottom: 0;
        box-shadow: 0 5px 20px 0 rgba($success, .3);
        z-index: -1;
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $white;
    }
  }
}