.modal {
  
  &-backdrop {
    background-image: linear-gradient(to bottom, $secondary, $primary);

    &.show {
      opacity: .95;
    }
  }

  .close {
    width: 48px;
    height: 48px;
    padding: 0;
    display: block;
    background-image: url(../img/svg/close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 8px 8px 16px 0 rgba(101, 100, 100, 0.4);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: absolute;
    top: -24px;
    left: calc(50% - 24px);
  }

  &-dialog {
    margin: 40px 5px 5px;

    @include media-breakpoint-up(sm) {
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(md) {
      max-width: 720px;
    }

    &-centered {

      @include media-breakpoint-up(sm) {
        min-height: calc(100% - 45px);
      }
    }
  }

  &-content {
    border: none;
  }

  &-body {
    padding: 0 60px;
    padding-top: 75px;
    padding-bottom: 50px;

    @include media-breakpoint-down(sm) {
      padding: 0 40px;
      padding-top: 50px;
      padding-bottom: 30px;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 20px;
      padding-top: 50px;
      padding-bottom: 10px;
    }

    p {
      margin-bottom: 25px;
    }
  }

  &-heading {
    margin-bottom: 50px;
    text-align: center;

    .h2 {
      margin-bottom: 10px;
    }
  }
}