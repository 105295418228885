//# Aplication header

.c-header {
  font-weight: 700;
  position: relative;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  &-inner {
    transition: $transition;

    .is-subnav-open &,
    .is-scroll & {
      background-color: $white;
    }

    .is-scroll:not(.is-subnav-open) & {
      box-shadow: 0 10px 40px rgba($black, .05);
    }
  }

  &-wrap {
    display: flex;
    align-items: center;
  }

  &-logo,
  &-btn {
    width: 300px;

    @include media-breakpoint-down(lg) {
      width: 200px;
    }

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  &-logo {
    transition: $transition;
    position: relative;
    z-index: 1;
    margin-left: -20px;

    @include media-breakpoint-down(md) {
      margin-left: -10px;
      margin-right: 40px;
    }

    @media (max-width: 480px) {
      margin-right: 20px;
    }

    .is-home:not(.is-logo-visible) & {
      opacity: 0;
      visibility: hidden;

      @include media-breakpoint-down(md) {
        opacity: 1;
        visibility: visible;
      }
    }

    &__link {
      padding: 20px;
      display: inline-block;

      @include media-breakpoint-down(md) {
        padding: 10px;
      }
    }

    img {
      max-width: 86px;
      height: auto;

      @include media-breakpoint-down(sm) {
        max-width: 70px;
      }
    }
  }

  &-nav {
    flex-grow: 1;
    text-align: center;

    @include media-breakpoint-down(md) {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      transition: $transition;
      padding-top: 80px;
      opacity: 0;
      visibility: hidden;
      display: flex;
      text-align: center;
      font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 68px;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: .2;
      display: none;
      @extend .u-primary-gradient;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    .is-menu-open & {

      @include media-breakpoint-down(md) {
        opacity: 1;
        visibility: visible;
      }
    }

    &__wrap {

      @include media-breakpoint-down(md) {
        flex-grow: 1;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;
        z-index: 1;
      }
    }

    &__list {
      display: inline-flex;
      list-style: none;
      padding: 0;
      margin: 0;

      @include media-breakpoint-down(md) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 90px);
        padding: 40px 0;
      }
    }

    &__link {
      color: currentColor;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      .is-active & {
        color: $primary-dark;
        text-decoration: none;
      }
    }

    &__icon {
      margin-left: 5px;
      transition: transform $transition;
      line-height: 0;

      .is-subnav-open & {
        transform: rotate(-180deg);
      }
    }
  }

  &-subnav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: -1;
    // transform: translateY(-100%);
    transform: perspective(400px) rotate3d(1,0,0,-90deg);
    transform-origin: 50% 0;
    overflow: hidden;
    transition: $transition;

    @include media-breakpoint-down(md) {
      position: static;
      transform: none;
      max-height: 0;
      font-size: 16px;
    }

    .is-scroll & {
      box-shadow: 0 10px 40px rgba($black, .05);
      background-color: $white;

      @include media-breakpoint-down(md) {
        box-shadow: none;
        background-color: transparent;
      }
    }

    .is-subnav-open & {
      box-shadow: 0 10px 40px rgba($black, .05);
      transform: perspective(400px) rotate3d(0, 0, 0, 0);
      background-color: $white;

      @include media-breakpoint-down(md) {
        box-shadow: none;
        background-color: transparent;
        max-height: 500px;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: .2;

      @extend .u-primary-gradient;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__list {
      list-style: none;
      padding: 20px 0;
      margin: 0;
      display: inline-flex;
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        margin-top: -10px;
        padding: 0;
        padding-bottom: 15px;
      }

      .is-subnav-open & {
        opacity: 1;
        visibility: visible;
      }
    }

    &__item {

      &.is-active {
        .c-header-subnav__link {
          color: $primary-dark;
        }
      }
    }

    &__link {
      color: currentColor;
      display: block;
      padding: 20px;

      @include media-breakpoint-down(md) {
        padding: 9px 20px;
      }

      &:hover {
        color: $primary-dark;
        text-decoration: none;
      }
    }
  }

  &-btn {
    text-align: right;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
      margin-left: auto;
      flex-grow: 1;
      text-align: center;
    }

    .btn {

      @include media-breakpoint-down(sm) {
        padding: 10px 25px;
      }
    }
  }

  &-hamburger {
    margin-left: 40px;
    text-align: right;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      width: 96px;
    }

    @include media-breakpoint-down(sm) {
      width: 80px;
    }

    @media (max-width: 480px) {
      margin-left: 20px;
    }

    &__link {
      display: inline-block;
      vertical-align: middle;
      padding: 13px 0;
    }

    &__icon {
      width: 44px;
      height: 15px;
      position: relative;

      > div {
        position: absolute;
        background-color: currentColor;
        left: 0;
        width: 100%;
        height: 3px;
        transition: $transition;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          bottom: 0;
        }
      }

      .is-menu-open & {

        > div {

          &:nth-child(1) {
            top: calc(50% - 2px);
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            bottom: calc(50% - 2px);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  .o-box {
    max-width: 1920px;
  }
}


.is-menu-open {

  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
}