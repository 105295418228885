// Application variables
//
//


// Color
//
//

$white: #fff;
$black: #000;

$gray-100:      #f5f5f5;
$gray-200:      #eee;
$gray-300:      #ccc;
$gray-500:      #aaa;
$gray-600:      #888;
$gray-700:      #555;
$gray-800:      #333;
$gray-900:      #222;

$blue:          #A4C9D8;
$blue-dark:     #29576E;
$indigo:        #6610f2;
$purple:        #6f42c1;
$pink:          #E7B6B6;
$pink-dark:     #CC6D5C;
$red:           #ED0000;
$orange:        #fd7e14;
$yellow:        #ffc107;
$green:         #00A203;
$teal:          #20c997;
$cyan:          #17a2b8;

$primary:          $pink;
$primary-dark:     $pink-dark;
$secondary:        $blue;
$secondary-dark:   $blue-dark;
$success:          $green;
$warning:          $yellow;
$danger:           $red;

$body-color: $secondary-dark;
$body-bg:    $white;


// Links
//
//

$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Grid
//
//

$grid-columns:      12;
$grid-gutter-width: 80px;

$gutter:            $grid-gutter-width;
$gutter-xs:         round($gutter * 0.33);
$gutter-xs-2:       round($gutter * 0.66);
$gutter-sm:         round($gutter * 0.5);
$gutter-md:         round($gutter * 1.5);
$gutter-lg:         round($gutter * 2);

$spacer:            1rem;

$spacer-xs:         ($spacer * 0.33);
$spacer-xs-2:       ($spacer * 0.66);
$spacer-sm:         ($spacer * 0.5);
$spacer-md:         ($spacer * 1.5);
$spacer-lg:         ($spacer * 2);

$body-max-width:    1980px;
$box-max-width:     1440px + $gutter;

$breakpoints-xs:   0;
$breakpoints-sm:   576px;
$breakpoints-md:   768px;
$breakpoints-lg:   992px;
$breakpoints-xl:   1260px;
$breakpoints-xxl:  1520px;

$grid-breakpoints: (
  xs: $breakpoints-xs,
  sm: $breakpoints-sm,
  md: $breakpoints-md,
  lg: $breakpoints-lg,
  xl: $breakpoints-xl,
  xx: $breakpoints-xxl
);

$container-sm:   540px;
$container-md:   720px;
$container-lg:   960px;
$container-xl:   1200px;

$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl
);


// Components
//
// Define common padding and border radius sizes and more.

$cubic-bezier:       cubic-bezier(0.25, 0.1, 0.25, 1);
$transition:         .3s $cubic-bezier;
$transition-base:    $transition;

$font-weights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900
);


// Fonts
//
//

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            Roboto, $font-family-sans-serif;
$font-family-comfortaa:       Comfortaa, $font-family-base;

$font-size-base:              1.6rem;
$line-height-base:            1.44444;



// Btn & Input

$btn-padding-y: 1.3rem;
$btn-padding-x: 3rem;