.c-error {
  margin-top: -100px;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin-top: -80px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 0;
    margin-top: 0;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  &-wrap {
    margin: 0 auto;
    text-align: center;
    padding: 130px 0;
    width: 100%;
    max-width: 630px;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
  }

  &-number {
    font-size: 200px;
    line-height: 190px;
    display: block;
    color: $secondary;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: $font-family-comfortaa;

    @include media-breakpoint-down(xs) {
      font-size: 100px;
      line-height: 92px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &-title {
    margin-bottom: 30px;
  }

  &-text {
    font-size: 18px;
    line-height: 26px;
    display: block;
    margin-bottom: 40px;
  }

  &-btn {

    .btn {
      width: 180px;
      max-width: 100%;
    }
  }
}