.c-contacts {
  margin-top: -100px;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(md) {
      margin-top: -80px;
  }

  @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding-bottom: 90px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 50px;
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
      min-height: 0;
    }
  }

  &-info {
    width: 50%;
    line-height: 40px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-right: 40px;
    position: relative;

    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 70px;
      margin-bottom: 40px;
      padding: 0;
      text-align: center;
    }

    &__wrap {
      padding: 100px 0;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }

  &-title {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
      width: 100%;
    }
  }

  &-data {
    margin-bottom: 30px;

    p {
      margin: 0;
    }

    a {
      color: $body-color;
      text-decoration: none;

      &:hover {
        color: $primary-dark;
      }
    }
  }

  &-social {

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__link {
      color: currentColor;
      display: inline-flex;
      align-items: center;

      &:hover {
        text-decoration: none;
        color: $primary-dark;
      }
    }

    &__icon {
      margin-right: 15px;

      svg {
        vertical-align: middle;
      }
    }
  }

  &-media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    position: relative;
    padding: 100px 0;

    @include media-breakpoint-down(sm) {
      padding: 0;
      width: 100%;
    }

    &__bg {
      position: absolute;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      @include media-breakpoint-down(sm) {
        display: none;
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    &__circle {
      max-width: 100%;
      width: 600px;
      box-shadow: 10px 10px 20px 0 rgba($primary, .3);
      background-image: linear-gradient(145deg, $white, #F2D6D6);
      position: relative;

      &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
      }

      @include media-breakpoint-down(sm) {
        height: 300px;
        width: 100%;
      }

      > img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__map {
      position: absolute;
      left: 20px;
      top: 20px;
      right: 20px;
      bottom: 20px;
      overflow: hidden;
      z-index: 1;

      @include media-breakpoint-down(xs) {
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        left: 0;
        top: 0;
        box-shadow: inset 2px 2px 10px 0 rgba($black, .1);

        @include media-breakpoint-down(sm) {
          border-radius: 0;
        }
      }
    }
  }

  .c-polygons {

    &-item {

      &:nth-child(1) {
        width: 10%;
        left: 30%;
        top: 15%;

        @include media-breakpoint-down(xs) {
          width: 15%;
        }
      }

      &:nth-child(2) {
        width: 13%;
        right: 20px;
        top: 35%;
      }

      &:nth-child(3) {
        width: 26%;
        right: 20%;
        bottom: 10%;

        @include media-breakpoint-down(sm) {
          bottom: -20px;
          right: inherit;
          left: 0;
        }
      }
    }
  }

  &-develop {
      position: absolute;
      left: 0;
      bottom: 28px;
      width: 100%;
      text-align: center;

      a {
          color: $body-color;
          text-decoration: none;

          &:hover {
              color: $primary-dark;
          }
      }

      svg,
      img {
          width: 42px;
          height: 14px;
      }
  }
}
