.c-lessons {
  padding-top: 220px;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    padding-top: 100px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 70px;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 50px;
  }

  &-top {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 120px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 70px;
    }

    &__wrap {
      margin: 0 auto;
      max-width: 680px;
    }

    .c-polygons {

      &-item {

        &:nth-child(1) {
          width: 8%;
          left: 7%;
          top: 15%;

          @include media-breakpoint-down(xs) {
            width: 16%;
          }
        }

        &:nth-child(2) {
          width: 12%;
          top: -50%;
          right: 5%;

          @include media-breakpoint-down(sm) {
            top: -10%;
          }

          @include media-breakpoint-down(xs) {
            width: 20%;
          }
        }

        &:nth-child(3) {
          width: 52%;
          right: -24%;
          top: 200%;

          @include media-breakpoint-down(xs) {
            width: 70%;
            right: -35%;
          }
        }
      }
    }
  }

  &-schedule {
    margin-bottom: 160px;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 70px;
    }

    &__wrap {
      max-width: 1280px;
      margin: 0 auto;
      overflow: hidden;
    }

    &__title {
      max-width: 680px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 60px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0 -20px;
      margin-bottom: -20px;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: -60px;
      }
    }

    &__item {
      width: 33.3333%;
      padding: 20px;
      position: relative;
      padding-bottom: 40px;
      margin-bottom: 20px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0;
        margin-bottom: 60px;
      }

        &:before {
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-image: linear-gradient(to bottom, $secondary, $primary);
        }

      &:first-child {

        &:before {
          content: '';
          width: 0px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-image: linear-gradient(to bottom, $secondary, $primary);
        }
      }

      &-title {
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        margin-bottom: 4px;
      }

      &-time {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        display: block;
        margin-bottom: 40px;
      }

      ul {
        list-style: none;
        padding: 0;
        line-height: 24px;

        li {
          padding-left: 20px;
          position: relative;

          &:before {
            content: '— ';
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &-about {
    margin-bottom: 220px;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px;
      margin-bottom: -160px;

      @include media-breakpoint-down(md) {
        margin: 0 -20px;
        margin-bottom: -80px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: -50px;
      }
    }

    &__col {
      width: 50%;
      padding: 0 40px;

      @include media-breakpoint-down(md) {
        padding: 0 20px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:nth-child(2) {
        padding-top: 160px;

        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
      }
    }

    &__item {
      margin-bottom: 160px;
      position: relative;
      padding-top: 100px;

      @include media-breakpoint-down(md) {
        margin-bottom: 80px;
        padding-top: 70px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
      }
    }

    &__img {
      position: absolute;
      left: 50px;
      top: 0;
      z-index: -1;
      width: 150px;

      @include media-breakpoint-down(md) {
        width: 100px;
        left: 25px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__title {
        margin-bottom: 30px;
    }

    &__text {

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 26px;
        list-style: none;
        padding: 0;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 24px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin: 0;
          padding-left: 20px;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          &::before {
            content: '— ';
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
