//# Aplication footer

.c-footer {
  margin-bottom: 120px;
  font-size: 18px;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 90px;
  }

  &-title {
    text-align: center;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }
  }

  &-wrap {
    position: relative;
  }

  &-inner {
    position: relative;

    .c-polygons-item {

      &:nth-child(1) {
        width: 2.29166666667%;
        right: 24.305555%;
        top: -110px;
      }

      &:nth-child(2) {
        width: 6.11111111111%;
        left: 23.61111111%;
        top: -60px;
      }

      &:nth-child(3) {
        width: 8.888888888%;
        left: 13.8888888%;
        bottom: -30px;
        z-index: 1;
      }

      &:nth-child(4) {
        width: 15.9027777%;
        right: 9.027777777%;
        top: -10px;
        z-index: 1;
      }
    }
  }

  &-map {
    position: relative;
    z-index: 1;
    width: 37.5%;
    margin: 0 auto;
    background-image: linear-gradient(145deg, $white, #F2D6D6);
    box-shadow: 10px 10px 20px 0 rgba($primary, .3);
    max-height: 400px;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }

    @include media-breakpoint-down(md) {
      height: 300px;
      width: 100%;
      border-radius: 0;
      background-image: linear-gradient(145deg, $white, rgba($primary, .5));
    }

    > img {
      max-width: 100%;
      height: auto;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__inner {
      position: absolute;
      left: 20px;
      top: 20px;
      bottom: 20px;
      right: 20px;
      background-color: $white;
      box-shadow: inset 2px 2px 10px 0 rgba($black, .11);
      z-index: 1;

      @include media-breakpoint-down(md) {
        border-radius: 0;
      }

      @include media-breakpoint-down(xs) {
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        box-shadow: inset 2px 2px 10px 0 rgba($black, .11);
        pointer-events: none;

        @include media-breakpoint-down(md) {
          border-radius: 0;
        }
      }
    }
  }

  &-info {
    background-image: url(../img/home/footer.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 40px 0;
    z-index: 1;
    line-height: 40px;

    @include media-breakpoint-down(md) {
      position: relative;
      background: none;
      padding: 0;
      margin-bottom: 40px;
      transform: none;
    }

    &__wrap {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        align-items: flex-start;
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }
  }

  &-contacts,
  &-social {
    width: 31.25%;

    @include media-breakpoint-down(md) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &-contacts {

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      text-align: center;
    }

    p {
      margin: 0;
    }

    a {
      color: $body-color;
      text-decoration: none;

      &:hover {
        color: $primary-dark;
      }
    }
  }

  &-social {
    margin-left: auto;
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: left;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0 -25px;
      display: inline-flex;
      align-items: center;

      @include media-breakpoint-down(lg) {
        margin: 0 -15px;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__link {
      padding: 0 25px;
      color: currentColor;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }

      &:hover {
        text-decoration: none;
        color: $primary-dark;
      }
    }

    &__icon {
      margin-right: 15px;

      svg {
        vertical-align: middle;
      }
    }
  }

  &-develop {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: -74px;
    width: 100%;

    @include media-breakpoint-down(md) {
        bottom: -60px;
    }

    a {
        color: $body-color;
        text-decoration: none;

        &:hover {
            color: $primary-dark;
        }
    }

    svg,
    img {
        width: 42px;
        height: 14px;
    }
  }
}
