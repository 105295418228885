.c-registration {
  overflow: hidden;

  &-wrap {
    margin-top: 220px;

    @include media-breakpoint-down(lg) {
      margin-top: 100px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 70px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 50px;
    }
  }

  &-types {
    position: relative;
    text-align: center;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      position: absolute;
      opacity: .2;
      z-index: -1;

      @extend .u-primary-gradient;
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -20px;
      padding: 40px 0;

      @include media-breakpoint-down(md) {
        padding: 30px 0;
        padding-bottom: 0;
      }

      @include media-breakpoint-down(xs) {
        margin: 0 -10px;
      }
    }

    &__col {
      padding: 0 20px;

      @include media-breakpoint-down(md) {
        padding: 0 10px;
        margin-bottom: 30px;
      }
    }

    &__link {

      &.is-active {
        color: $secondary-dark;
        pointer-events: none;
      }
    }
  }

  &-top {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 120px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 70px;
    }

    &__wrap {
      margin: 0 auto;
      max-width: 680px;
    }

    .c-polygons {

      &-item {

        &:nth-child(1) {
          width: 8%;
          bottom: 110%;
          left: 15%;

          @include media-breakpoint-down(xs) {
            width: 15%;
            bottom: 80%;
            left: 5%;
          }
        }

        &:nth-child(2) {
          width: 14%;
          top: 10%;
          right: 5%;

          @include media-breakpoint-down(md) {
            right: -50px;
            top: -50px;
          }

          @include media-breakpoint-down(xs) {
            width: 20%;
            right: -20px;
          }
        }

        &:nth-child(3) {
          width: 10%;
          bottom: -100px;
          left: 5%;

          @include media-breakpoint-down(xs) {
            width: 15%;
          }
        }

        &:nth-child(4) {
          width: 133.333%;
          top: 70%;
          right: -55%;

          @include media-breakpoint-down(md) {
            top: 200%;
          }
        }

        &:nth-child(5) {
          width: 19%;
          left: 90%;
          top: 280%;

          @include media-breakpoint-down(md) {
            top: 400%;
          }

          @include media-breakpoint-down(sm) {
            top: 300%;
          }
        }
      }
    }
  }

  &-info {
    margin-bottom: 160px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 70px;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      @include media-breakpoint-down(sm) {
        margin-bottom: -50px;
      }
    }

    &__col {
      padding: 0 20px;
      width: 50%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 50px;
      }

      &:nth-child(2) {
        padding-top: 100px;

        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }

        .c-registration-info__text {
          margin-left: auto;

          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
      }
    }

    &__text {
      max-width: 480px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      p {
        font-size: 18px;
        line-height: 26px;

        // @include media-breakpoint-down(md) {
        //   font-size: 16px;
        //   line-height: 24px;
        // }
      }

      ul {
        padding: 0;
        list-style: none;

        li {
          padding-left: 20px;
          position: relative;

          &:before {
            content: '— ';
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    &__subtitle {
      margin-top: 40px;
      margin-bottom: 20px;
      font-family: $font-family-comfortaa;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      color: $primary-dark;
    }

    .c-polygons {

      &-item {

        // &:nth-child(1) {
        //   width: 8%;
        //   top: -160px;
        //   left: 15%;

        //   @include media-breakpoint-down(md) {
        //     width: 15%;
        //   }
        // }

        &:nth-child(1) {
          width: 21%;
          bottom: -80px;
          left: 7%;

          @include media-breakpoint-down(md) {
            width: 40%;
          }
        }

        &:nth-child(2) {
          width: 8%;
          top: 45%;
          left: 40%;

          @include media-breakpoint-down(md) {
            width: 20%;
          }
        }

        &:nth-child(3) {
          width: 9%;
          top: -60px;
          right: 30%;

          @include media-breakpoint-down(md) {
            width: 15%;
          }
        }

        &:nth-child(4) {
          width: 10%;
          right: -100px;
          bottom: -100px;

          @include media-breakpoint-down(lg) {
            right: 0;
          }

          @include media-breakpoint-down(md) {
            width: 20%;
          }

          @include media-breakpoint-down(sm) {
            bottom: 5%;
          }
        }
      }
    }
  }

  &-form {
    position: relative;

    &__heading {
      text-align: center;
      margin-bottom: 80px;
    }

    &__info {
      font-size: 18px;
    }

    &__block {
      max-width: 480px;
      margin: 0 auto;
      margin-bottom: 80px;

      &.t-big {

        @include media-breakpoint-up(md) {
          max-width: 840px;
        }
      }

      &:before {
        content: '';
        width: 60px;
        height: 4px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        @extend .u-primary-gradient;
      }

      &-heading {
        margin-bottom: 40px;
        text-align: center;
      }

      &-title {
        font-size: 18px;
        line-height: 26px;
        font-family: $font-family-comfortaa;
        font-weight: 700;
        color: $primary-dark;
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin: 0;
        }
      }

      &-info {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__types {

      @include media-breakpoint-up(md) {
        margin-top: -14px;
        text-align: center;
      }

      &-list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 -30px;
        flex-wrap: wrap;
        justify-content: center;

        @include media-breakpoint-down(md) {
          margin: 0 -15px;
        }
      }

      &-item {
        padding: 0 30px;
        width: 33.333%;

        @include media-breakpoint-down(md) {
          padding: 0 15px;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .price {

        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      .img {
        position: absolute;
        left: calc(50% - 55px);
        top: 0;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .custom-control {

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          padding: 0;
        }

        &-label {

          @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-top: 125px;
            color: rgba($body-color, .5) !important;

            &:hover {
              color: $body-color !important;
            }

            &:before {
              left: calc(50% - 10px);
              top: 45px;
            }

            &:after {
              left: calc(50% - 5px);
              top: 50px;
            }
          }
        }
      }

      .custom-control-input:checked ~,
      .custom-checkbox .custom-control-input:checked ~,
      .custom-radio .custom-control-input:checked ~ {

        .custom-control-label {

          @include media-breakpoint-up(md) {
            color: $body-color !important;
          }
        }
      }
    }

    &__success {
      font-size: 18px;
      line-height: 26px;
      color: $success;
      text-align: center;
      margin-top: 30px;
      display: block;
    }

    .c-polygons {

      &-item {

        &:nth-child(1) {
          width: 15%;
          bottom: -40px;
          left: 10%;

          @include media-breakpoint-down(md) {
            left: 0;
          }

          @include media-breakpoint-down(sm) {
            bottom: 300px;
            left: 50%;
          }

          @include media-breakpoint-down(xs) {
            width: 30%;
          }
        }

        &:nth-child(2) {
          width: 10%;
          bottom: 100px;
          right: 10%;

          @include media-breakpoint-down(md) {
            right: 0;
          }

          @include media-breakpoint-down(sm) {
            bottom: 150px;
          }

          @include media-breakpoint-down(xs) {
            width: 20%;
          }
        }
      }
    }
  }
}
