.lg-backdrop {
  background-image: linear-gradient(to bottom, $secondary, $primary);
  opacity: 0;
  transition: $transition;

  &.in {
    opacity: .95;
  }
}

.lg-img-wrap {

  img {
    box-shadow: 10px 10px 20px 0 rgba($black, .2);
  }
}

#lg-counter {
  display: none;
}

.lg-toolbar {
  display: flex;
  justify-content: center;

  .lg-close {
    width: 48px;
    height: 48px;
    padding: 0;
    display: block;
    background-image: url(../img/svg/close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 20px;
    box-shadow: 8px 8px 16px 0 rgba(#656464, .4);
    border-radius: 50%;
  
    &:after {
      display: none;
    }
  }
}

.lg-sub-html {
  padding: 50px 20px;
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }
}

.lg-actions {

  .lg-next,
  .lg-prev {
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .lg-prev {
    transform: rotate(180deg);
  }
}