.c-feeding {
  overflow: hidden;
  padding-bottom: 150px;

  @include media-breakpoint-down(md) {
    padding-bottom: 70px;
  }

  &-about {
    position: relative;
    margin-bottom: 180px;
    margin-top: 100px;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 70px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    &__wrap {
      display: flex;
      margin: 0 -40px;
      align-items: center;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        margin: 0 -20px;
      }
    }

    &__media,
    &__info {
      width: 50%;
      padding: 0 40px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0 20px;
      }
    }

    &__info {

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }

      &-wrap {
        margin-left: auto;
        width: 100%;
        max-width: 480px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }
      }
    }

    &__text {
      font-size: 18px;

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__media {

      @include media-breakpoint-down(sm) {
        order: 1;
      }

      &-wrap {
        margin-right: -40px;
        margin-left: -40px;

        @media (min-width: 1520px) {
          margin-left: calc(((100vw - 1530px) / 2 + 96px + 40px) * -1);
        }

        @media (min-width: 1920px) {
          margin-left: -335px;
        }

        @include media-breakpoint-down(sm) {
          margin-left: -40px;
          margin-right: -40px;
        }

        @include media-breakpoint-down(xs) {
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }

    &__img {

      &:before {
        padding-top: 62.56%;
      }
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 5.5%;
        left: 53%;
        top: 60%;

        @include media-breakpoint-down(sm) {
          width: 10%;
          left: inherit;
          right: 30%;
          top: -20px;
        }
      }

      &:nth-child(2) {
        width: 10%;
        right: -60px;
        top: -40px;

        @include media-breakpoint-down(sm) {
          width: 20%;
          right: -20px;
          top: 1%;
        }
      }

      &:nth-child(3) {
        width: 20%;
        right: 5%;
        bottom: -100px;

        @include media-breakpoint-down(sm) {
          width: 30%;
          bottom: inherit;
          top: 20%;
          right: 15%;
        }

        @include media-breakpoint-down(xs) {
          top: 30%;
        }
      }
    }
  }

  &-menu {
    max-width: 1340px;
    margin: 0 auto;
    position: relative;

    &__title {
      margin-bottom: 100px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
      }
    }

    &__heading {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding-bottom: 1px;
      margin-bottom: 50px;

      @include media-breakpoint-down(lg) {
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;

        @extend .u-primary-gradient;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__nav {
      font-size: 24px;
      line-height: 32px;
      font-family: $font-family-comfortaa;
      text-transform: uppercase;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 28px;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }

      &-list {
        display: inline-flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0 -25px;

        @include media-breakpoint-down(md) {
          margin: 0 -15px;
        }
      }

      &-link {
        color: $secondary-dark;
        opacity: .5;
        padding: 25px;
        display: block;

        @include media-breakpoint-down(md) {
          padding: 20px 15px;
        }

        &:hover,
        &.active {
          opacity: 1;
          color: $secondary-dark;
          text-decoration: none;
        }

        &.active {
          color: $primary-dark;
        }
      }
    }

    &__date {
      font-weight: 700;
      margin-left: auto;

      @include media-breakpoint-down(lg) {
        order: -1;
        width: 100%;
        display: block;
        margin-bottom: 70px;
        font-size: 12px;
        line-height: 12px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }
    }

    &__content {

      &-item {
        display: none;
        position: relative;

        @include media-breakpoint-down(sm) {
          display: block;
          opacity: 1 !important;
        }

        &.active {
          display: block;
        }

        &:not(:last-child) {

          @include media-breakpoint-down(sm) {
            padding-bottom: 1px;
          }

          &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: none;
            @extend .u-primary-gradient;

            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
        }

        &:last-child {

          .c-feeding-menu__schedule {
            padding-bottom: 0;
          }
        }
      }

      &-toggle {
        font-size: 20px;
        line-height: 28px;
        font-family: $font-family-comfortaa;
        text-transform: uppercase;
        text-align: center;
        display: block;
        padding: 20px 15px;
        color: $secondary-dark;
        opacity: .5;

        @include media-breakpoint-up(md) {
          display: none;
        }

        &:hover,
        &:not(.collapsed) {
          opacity: 1;
          color: $secondary-dark;
          text-decoration: none;
        }

        &:not(.collapsed) {
          color: $primary-dark;
        }
      }

      &-collapse {

        @include media-breakpoint-up(md) {
          display: block !important;
        }
      }
    }

    &__schedule {
      line-height: 24px;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        padding-top: 10px;
        padding-bottom: 30px;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0 -20px;
        margin-bottom: -50px;

        @include media-breakpoint-down(sm) {
          margin-bottom: -30px;
        }
      }

      &-item {
        width: 25%;
        padding: 0 20px;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
          width: 50%;
        }

        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }

        @include media-breakpoint-down(xs) {
          width: 100%;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            position: relative;
            padding-left: 10px;

            &:before {
              content: '-';
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          p {
            margin-bottom: 0;
          }
        }
      }

      &-title {
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
      }

      &-time {
        font-size: 12px;
        line-height: 14px;
        color: $secondary;
        font-family: $font-family-base;
        margin-left: 5px;
        vertical-align: super;
        white-space: nowrap;
      }
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 13%;
        right: 0;
        bottom: -190px;

        @include media-breakpoint-down(md) {
          bottom: 0px;
        }
      }
    }
  }
}