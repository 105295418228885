.c-gallery {
  padding-top: 100px;
  margin-bottom: 100px;
  position: relative;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 70px;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 50px;
  }

  &-tabs {
    margin-bottom: 140px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 40px;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;
    }

    &__link {
      color: $secondary;
      padding: 0 20px;
      display: block;

      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }

      &:hover {
        text-decoration: none;
        color: $secondary-dark;
      }

      &.active {
        color: $primary-dark;
      }
    }

    &__text {
      color: currentColor;
      margin: 0;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0 -20px;
    margin-bottom: -60px;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      margin-bottom: -40px;
    }

    @include media-breakpoint-down(xs) {
      margin-left: -10px;
      margin-right: -10px;
    }

    &__link {

      &:hover {
        text-decoration: none;
        color: $primary;

        .c-gallery-list__media {

          &-img {
            left: 10px;
            top: 10px;
            right: 10px;
            bottom: 10px;

            img {
              width: calc(100% + 20px);
              margin: -10px;
            }
          }

          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }

    &__item {
      padding: 0 20px;
      width: 25%;
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        width: 33.333%;
      }

      @include media-breakpoint-down(sm) {
        width: 50%;
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(xs) {
        padding: 0 10px;
      }

      &:nth-child(odd) {

        @include media-breakpoint-up(lg) {
          margin-top: -40px;
        }
      }

      &:nth-child(3n+2) {

        @include media-breakpoint-between(md, md) {
          margin-top: -40px;
        }
      }
    }

    &__media {
      position: relative;
      background-image: linear-gradient(to bottom, $secondary, $primary);
      margin-bottom: 20px;

      &::before {
        content: '';
        width: 100%;
        padding-bottom: 100%;
        display: block;
      }

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($secondary, .6);
        opacity: 1;
        visibility: visible;
        transition: $transition;

        @include media-breakpoint-down(sm) {
          opacity: 0;
          visibility: hidden;
        }
      }

      &-img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        transition: $transition;

        @include media-breakpoint-down(sm) {
          left: 10px;
          top: 10px;
          right: 10px;
          bottom: 10px;

          img {
            width: calc(100% + 20px);
            margin: -10px;
          }
        }

        img {
          width: 100%;
          margin: 0;
          height: auto;
          transition: $transition;
        }
      }

      &-play {
        position: absolute;
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        z-index: 1;
        box-shadow: 8px 8px 16px 0 rgba(#656464, .4);
        border-radius: 50%;

        @include media-breakpoint-down(xs) {
          width: 60px;
          height: 60px;
          left: calc(50% - 30px);
          top: calc(50% - 30px);
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
      font-family: $font-family-comfortaa;
      font-weight: 700;
      display: block;
      margin-bottom: 10px;
    }

    &__date {
      display: block;
      color: $body-color;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
    }
  }

  &-content {

    &__item {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  &-title {

    .lg-sub-html & {
      color: $white;
      margin-bottom: 20px;
    }
  }

  &-date {
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    display: block;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    .lg-sub-html & {
      color: $body-color;
      margin-bottom: 0;
    }
  }

  &-photo {
    max-width: 1300px;
    margin: 0 auto;

    &__list {
      padding: 0;
      margin: 0 -10px;
      margin-bottom: -20px;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    }

    &__item {
      padding: 0 10px;
      margin-bottom: 20px;
      width: 16.6666%;

      @include media-breakpoint-down(md) {
        width: 20%;
      }

      @include media-breakpoint-down(sm) {
        width: 25%;
      }

      @include media-breakpoint-down(xs) {
        width: 33.333%;
      }

      @media (max-width: 480px) {
        width: 50%;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__link {
      position: relative;
      display: block;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($secondary, .6);
        transition: $transition;

        @include media-breakpoint-down(sm) {
          opacity: 0;
        }
      }

      &:hover {

        &:after {
          opacity: 0;
        }
      }
    }
  }

  &-video {
    max-width: 1300px;
    margin: 0 auto;

    &__list {
      padding: 0;
      margin: 0 -10px;
      margin-bottom: -20px;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    }

    &__item {
      padding: 0 10px;
      margin-bottom: 20px;
      width: 50%;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__link {
      position: relative;
      display: block;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($secondary, .6);
        transition: $transition;

        @include media-breakpoint-down(sm) {
          opacity: 0;
        }
      }

      &:hover {

        &:after {
          opacity: 0;
        }
      }
    }

    &__play {
      width: 80px;
      height: 80px;
      position: absolute;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
      z-index: 1;

      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .c-polygons {

    &-item {

      &:nth-child(1) {
        width: 15.34722222%;
        top: -40px;
        left: 6%;

        @include media-breakpoint-down(md) {
          top: 20px;
        }

        @include media-breakpoint-down(xs) {
          width: 30%;
          left: -20px;
        }
      }

      &:nth-child(2) {
        width: 16.80555%;
        top: 40px;
        right: 6%;

        @include media-breakpoint-down(sm) {
          right: 0;
        }

        @include media-breakpoint-down(xs) {
          width: 30%;
          top: 100px;
          right: -20px;
        }
      }

      &:nth-child(3) {
        width: 5.34722222%;
        top: 170px;
        left: 30%;

        @include media-breakpoint-down(sm) {
          left: 20%;
          top: 120px;
        }

        @include media-breakpoint-down(xs) {
          width: 10%;
        }
      }
    }
  }
}