.c-specifics {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 200px;

  @include media-breakpoint-down(md) {
    margin-bottom: 150px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 100px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }

  &__wrap {
    max-width: 1270px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 180px;

    @include media-breakpoint-down(md) {
      margin-bottom: 120px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 80px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 -25px;
    margin-bottom: -140px;

    @include media-breakpoint-down(md) {
      margin-bottom: -70px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: -50px;
    }

    @media (max-width: 480px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__item {
    width: 25%;
    padding: 0 25px;
    margin-bottom: 140px;

    @include media-breakpoint-down(md) {
      width: 33.333%;
      margin-bottom: 70px;
    }

    @include media-breakpoint-down(sm) {
      width: 50%;
      margin-bottom: 50px;
    }

    @media (max-width: 480px) {
      padding: 0 15px;
    }

    &:nth-child(odd) {

      @include media-breakpoint-up(lg) {
        margin-top: -80px;
      }
    }

    &:nth-child(3n+2) {

      @include media-breakpoint-between(md, md) {
        margin-top: -40px;
      }
    }

    &:hover {

      .c-specifics {

        &__img {

          > img {

            @include media-breakpoint-up(md) {
              transform: scale(1.285);
              opacity: 0;
            }
          }

          &-overlay {
            opacity: 1;
            transform: scale(1.285);
          }

          &-icon {
            color: $secondary-dark;
          }
        }

        &__text {
          opacity: 1;
        }
      }
    }
  }

  &__img {
    position: relative;
    display: inline-block;
    width: 140px;
    max-width: 100%;
    margin-bottom: 40px;

    .touchevents & {
      margin-bottom: 20px;
    }

    > img {
      transition: $transition;
    }

    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: $transition;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      img {
        width: calc(127.1428%);
        height: auto;
        margin-left: -6.5%;
        margin-top: -6.5%;
      }
    }

    &-icon {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: $secondary;
      transition: $transition;
      max-width: 40%;

      .touchevents & {
        color: $secondary-dark;
      }

      svg {
        vertical-align: middle;
        max-width: 100%;
        height: auto;
      }
    }

    > img {
      margin-left: -1.42857%;
      margin-top: -1.42857%;
      margin-right: -4.2857%;
      margin-bottom: -4.2857%;
      width: calc(105.7142857%);
      height: auto;
    }
  }

  &__text {
    display: block;
    opacity: .5;
    transition: $transition;

    .touchevents & {
      opacity: 1;
    }
  }
}