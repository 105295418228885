.c-about {
  overflow: hidden;

  &-top {
    font-size: 18px;
    line-height: 26px;
    margin-top: 80px;
    margin-bottom: 300px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 70px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    &__wrap {
      display: flex;
      align-items: center;
      margin: 0 -40px;

      @include media-breakpoint-down(md) {
        margin: 0 -20px;
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }

    &__info,
    &__media {
      width: 50%;
      padding: 0 40px;

      @include media-breakpoint-down(md) {
        padding: 0 20px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &__info {

      @include media-breakpoint-down(sm) {
        margin-bottom: 80px;
      }
    }

    &__text {
      max-width: 480px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__circle {
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 10px 10px 30px 0 rgba(#AC8C90, .7);
      background-image: linear-gradient(to bottom, $secondary, $primary);

      @include media-breakpoint-down(md) {
        max-width: 480px;
        margin: 0 auto;
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }

      &-img {
        position: absolute;
        left: 20px;
        top: 20px;
        bottom: 20px;
        right: 20px;
        overflow: hidden;
        border-radius: 50%;

        img {
          border-radius: 50%;
          width: calc(100% + 40px);
          height: auto;
          margin: -20px;
        }
      }
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 7.98611%;
        left: 35.13888%;
        top: 80px;

        @include media-breakpoint-down(md) {
          top: 0;
          left: 50%;
        }
      }

      &:nth-child(2) {
        width: 120%;
        left: -30.06944%;
        bottom: -200px;

        @include media-breakpoint-down(md) {
          left: -40%;
          bottom: -100px;
        }

        @include media-breakpoint-down(sm) {
          left: -60%;
        }
      }
    }
  }

  &-info {
    margin-bottom: 300px;
    font-size: 24px;
    line-height: 32px;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 100px;
    }

    &__wrap {
      max-width: 800px;
      margin: 0 auto;
    }

    p {

      &:last-child {
        margin-bottom: 0;
      }
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 15%;
        left: 0%;
        top: -200px;

        @include media-breakpoint-down(md) {
          top: -150px;
        }
      }

      &:nth-child(2) {
        width: 18.472222%;
        right: -80px;
        bottom: -180px;

        @include media-breakpoint-down(md) {
          right: 0;
          bottom: -90px;
        }
      }
    }
  }

  &-team {
    text-align: center;
    margin-bottom: 200px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 150px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 100px;
    }

    &__wrap {
      max-width: 1160px;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 110px;

      @include media-breakpoint-down(md) {
        margin-bottom: 60px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0 -25px;
      margin-bottom: -80px;
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-down(md) {
        margin-bottom: -50px;
      }

      @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    &__item {
      padding: 0 25px;
      margin-bottom: 80px;
      width: 33.333%;

      @include media-breakpoint-down(md) {
        width: 50%;
        margin-bottom: 50px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 15px;
      }

      @media (max-width: 480px) {
        width: 100%;
      }

      &:hover {

        .c-about-team__circle {

          &:after {
            box-shadow: 10px 10px 30px 0 rgba(#AD8C90, .7);
          }
        }
      }
    }

    &__circle {
      background-image: linear-gradient(to bottom, $secondary, $primary);
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        max-width: 260px;
      }

      &:before {
        content: '';
        width: 100%;
        padding-bottom: 100%;
        display: block;
      }

      &:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;
        bottom: 5px;
        right: 5px;
        border-radius: 50%;
        z-index: -1;
        transition: $transition;
      }

      &-img {
        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;
        overflow: hidden;
        border-radius: 50%;

        img {
          border-radius: 50%;
          width: calc(100% + 20px);
          margin: -10px;
          height: auto;
        }
      }
    }

    &__name {
      margin-bottom: 15px;

      a {
        color: currentColor;
        position: relative;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;

        &:hover {
          text-decoration: none;

          &:before {
            left: -50%;
          }
        }

        &:before {
          content: '';
          width: 150%;
          border-bottom: 2px dashed currentColor;
          position: absolute;
          left: 0;
          bottom: 2px;
          display: block;
          transition: $transition;
        }
      }
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 20.138888%;
        right: 0;
        bottom: -250px;

        @include media-breakpoint-down(md) {
          right: -40px;
          bottom: -50px;
        }

        @include media-breakpoint-down(xs) {
          right: 0;
          bottom: 0;
        }
      }

      &:nth-child(2) {
        width: 16.111111%;
        left: 0;
        bottom: 15%;

        @include media-breakpoint-down(md) {
          left: -40px;
        }

        @include media-breakpoint-down(sm) {
          left: 0;
        }

        @include media-breakpoint-down(xs) {
          left: 0;
          bottom: 40%;
        }
      }

      &:nth-child(3) {
        width: 6.041666%;
        right: 10%;
        bottom: 35%;

        @include media-breakpoint-down(md) {
          right: 0;
          bottom: 30%;
        }
      }
    }
  }

  &-registration {
    text-align: center;
    margin-bottom: 220px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    &__wrap {
      max-width: 800px;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 80px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }
    }

    &__text {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 26px;
    }

    .c-polygons-item {

      &:nth-child(1) {
        width: 9.791666%;
        left: 12%;
        top: 40px;

        @include media-breakpoint-down(xs) {
          width: 15%;
        }
      }

      &:nth-child(2) {
        width: 13.75%;
        right: 12%;
        bottom: -100px;

        @include media-breakpoint-down(xs) {
          width: 20%;
          right: 20px;
          bottom: -70px;
        }
      }
    }
  }
}