//# Scaffolding
//
//* Reset

html {
    font-size: 10px;
}

body {
    fill: $body-color;
}

* {
    outline: none !important;
}

.gm-style-cc {
    opacity: 0 !important;
}


//## Transitions
//
//*

button,
.btn,
a {
    transition: $transition;
}


//## Text
//
//*

// ** text selection
::selection {
    color: $white;
    background-color: $secondary;
}

p {
    margin-bottom: 26px;
}

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
    color: $primary-dark;
    font-family: $font-family-comfortaa;
    font-weight: 300;
    display: block;
}

.h1, h1 {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
    margin-top: 0;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 50px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 46px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 40px;
    }
}

.h2, h2 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 0;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 38px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 34px;
    }
}

// .h3, h3 {}

// .h4, h4 {}

// .h5, h5 {}

// .h6, h6 {}

@each $class,
$value in $font-weights {
    @include font-weight(".font-weight-#{$class}", $value);
}


.link-underline {
    position: relative;
    color: $primary-dark;
    font-weight: 700;
    padding: 3px 0;
    border-bottom: 2px solid $secondary-dark;

    &:hover {
        text-decoration: none;
        border-color: $primary;
    }
}


//## Forms
//
//*

textarea {
    resize: vertical;
}


//## Misc
//
//*

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}