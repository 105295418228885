//# Forms

.form {

  &-group {
    margin-bottom: 30px;

    label {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $primary-dark;
      margin-bottom: 20px;

      + input {
        margin-top: -12px;
      }
    }
  }

  &-control {
    border: 2px solid $body-color;
    padding: 19px 23px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;

    &::placeholder {
      color: rgba($body-color, .5);
    }

    &:focus {
      box-shadow: none;
      border-color: $body-color;
    }
  }

  &-info {
    display: block;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 18px;
  }
}

textarea.form-control {
  padding: 5px 0;
  border-left: none;
  border-right: none;
  border-top: none;
  box-sizing: content-box;
}

select.form-control {
  height: auto !important;
  padding: 5px 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  -webkit-appearance: none;
}

select.form-control:invalid {
  color: rgba($body-color, .5);
}

.custom {

  &-control {
    margin-bottom: 20px;
    padding: 0;

    &-label {
      color: $body-color !important;
      margin: 0 !important;
      display: block;
      cursor: pointer;
      padding-left: 30px;
      transition: $transition;

      .custom-control-input.is-invalid ~ & {
        color: $danger !important;
      }

      &:before {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background: none;
        border: 2px solid $body-color;
        top: 0;
        box-shadow: none !important;
        z-index: 1;
        background: $white;

        .custom-control-input:checked ~ &,
        .custom-checkbox .custom-control-input:checked ~ &,
        .custom-radio .custom-control-input:checked ~ &,
        .custom-control-input.is-invalid ~ & {
          background: $white;
        }

        .custom-control-input.is-invalid ~ & {
          border-color: $danger;
        }
      }

      &:after {
        width: 10px;
        height: 10px;
        background: $primary-dark !important;
        left: 5px;
        top: 5px;
        border-radius: 2px;
        opacity: 0;
        transition: $transition;
        z-index: 2;

        .custom-control-input:checked ~ &,
        .custom-checkbox .custom-control-input:checked ~ & {
          opacity: 1;
        }

        .custom-radio & {
          border-radius: 50%;
        }
      }
    }
  }
}