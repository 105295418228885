.c-polygons {

  &-item {
    position: absolute;
    pointer-events: none;
    z-index: -1;

    img,
    svg {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}