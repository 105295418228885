//# Grid

.o-box {
    @include make-container;

    max-width: $box-max-width;

    @include media-breakpoint-down(xs) {
        padding: 0 20px;
    }
}

main {
    padding-top: 100px;

    @include media-breakpoint-down(md) {
        padding-top: 80px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 68px;
    }
}